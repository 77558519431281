@use '../../mixins';

@use 'pattern/typography';
@use 'pattern/utility';
@use 'variables';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/overlay';
@use 'themes/mytheme/variables/panel';

.p-dialog {
  border-radius: general.$borderRadius;
  box-shadow: overlay.$overlayContainerShadow;
  border: overlay.$overlayContentBorder;
  max-width: utility.get-rem(600);

  .p-dialog-header {
    border-bottom: 1px solid variables.$icy-sky-blue;
    background: overlay.$dialogHeaderBg;
    color: overlay.$dialogHeaderTextColor;
    padding: utility.get-rem(24) utility.get-rem(24) utility.get-rem(20)
      utility.get-rem(24);
    border-top-right-radius: general.$borderRadius;
    border-top-left-radius: general.$borderRadius;

    .p-dialog-title {
      @extend %#{typography.$heading-l-pattern};
      color: variables.$primary-dnv-dark-blue;
    }

    .p-dialog-header-icon {
      @include mixins.action-icon();
      margin-right: general.$inlineSpacing;
      color: variables.$dusk-blue;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .p-dialog-content {
    background: overlay.$overlayContentBg;
    color: variables.$black;
    padding: utility.get-rem(24);
    font-size: utility.get-rem(16);

    &:last-of-type {
      border-bottom-right-radius: general.$borderRadius;
      border-bottom-left-radius: general.$borderRadius;
    }
  }

  .p-dialog-footer {
    border-top: 1px solid variables.$icy-sky-blue;
    background: overlay.$overlayContentBg;
    color: panel.$panelFooterTextColor;
    padding: utility.get-rem(20) utility.get-rem(24) utility.get-rem(24)
      utility.get-rem(24);
    text-align: right;
    border-bottom-right-radius: general.$borderRadius;
    border-bottom-left-radius: general.$borderRadius;
    display: flex;
    justify-content: space-between;
    font-size: utility.get-rem(16);

    button {
      margin: 0 general.$inlineSpacing 0 0;
      width: auto;
    }
  }

  &.p-confirm-dialog {
    .p-confirm-dialog-icon {
      font-size: general.$primeIconFontSize * 2;

      &.p-icon {
        width: general.$primeIconFontSize * 2;
        height: general.$primeIconFontSize * 2;
      }
    }

    .p-confirm-dialog-message {
      margin-left: general.$inlineSpacing * 2;
    }
  }
}
