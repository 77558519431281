* {
  box-sizing: border-box;
}

html {
  font-size: var(--dnv-font-base);
  text-rendering: optimizeLegibility;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  height: 100vh;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

button,
input[type="button"] {
  cursor: pointer;
}

ol,
ul {
  list-style: none;
}

img {
  border: 0;
  border-style: none;
}