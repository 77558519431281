@use '../../mixins';

@use 'pattern/utility';
@use 'variables';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/message';

.p-toast {
  min-width: utility.get-rem(280);

  .p-toast-message {
    margin: message.$toastMargin;
    box-shadow: message.$toastShadow;
    color: variables.$black;
    border-radius: utility.get-rem(10);

    .p-toast-message-content {
      padding: utility.get-rem(12);
      border-width: message.$toastBorderWidth;
      display: flex;
      align-items: center;
      gap: utility.get-rem(10);

      .p-toast-message-text {
        margin: message.$toastMessageTextMargin;
      }
      .p-toast-message-icon {
        font-size: message.$toastIconFontSize;
      }

      .p-icon:not(.p-toast-icon-close-icon) {
        width: message.$toastIconFontSize;
        height: message.$toastIconFontSize;
      }

      .p-toast-summary {
        font-weight: message.$toastTitleFontWeight;
      }

      .p-toast-detail {
        margin: message.$toastDetailMargin;
      }
    }

    .p-toast-icon-close {
      width: message.$toastIconFontSize;
      height: message.$toastIconFontSize;
      border-radius: general.$actionIconBorderRadius;
      background: transparent;
      transition: general.$actionIconTransition;
      margin-left: auto;
      color: variables.$black;

      &:focus-visible {
        @include mixins.focused();
      }
    }

    &.p-toast-message-info {
      background: variables.$light-cyan;
    }

    &.p-toast-message-success {
      background: variables.$message-success-bg;
    }

    &.p-toast-message-warn {
      background: variables.$message-warning-bg;
    }

    &.p-toast-message-error {
      background: variables.$misty-rose;
    }

    &.p-toast-message-alert {
      background: variables.$peach;
    }
  }
}
