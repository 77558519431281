@use '../../mixins';

@use 'themes/mytheme/variables/button';
@use 'themes/mytheme/variables/data';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/panel';

.p-fileupload {
  .p-fileupload-buttonbar {
    background: panel.$panelHeaderBg;
    padding: panel.$panelHeaderPadding;
    border: panel.$panelHeaderBorder;
    color: panel.$panelHeaderTextColor;
    border-bottom: 0 none;
    border-top-right-radius: general.$borderRadius;
    border-top-left-radius: general.$borderRadius;

    .p-button {
      margin-right: general.$inlineSpacing;

      &.p-fileupload-choose.p-focus {
        @include mixins.focused();
      }
    }
  }

  .p-fileupload-content {
    background: panel.$panelContentBg;
    padding: form.$fileUploadContentPadding;
    border: panel.$panelContentBorder;
    color: panel.$panelContentTextColor;
    border-bottom-right-radius: general.$borderRadius;
    border-bottom-left-radius: general.$borderRadius;

    &.p-fileupload-highlight {
      border-color: form.$fileUploadContentHoverBorder;
      border-style: dashed;
      background-color: general.$highlightBg;
    }
  }

  .p-progressbar {
    height: form.$fileUploadProgressBarHeight;
  }

  .p-fileupload-row {
    > div {
      padding: data.$tableBodyCellPadding;
    }
  }

  &.p-fileupload-advanced {
    .p-message {
      margin-top: 0;
    }
  }
}

.p-fileupload-choose {
  &:not(.p-disabled):hover {
    background: button.$buttonHoverBg;
    color: button.$buttonTextHoverColor;
    border-color: button.$buttonHoverBorderColor;
  }

  &:not(.p-disabled):active {
    background: button.$buttonActiveBg;
    color: button.$buttonTextActiveColor;
    border-color: button.$buttonActiveBorderColor;
  }
}
