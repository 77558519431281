@use '../../mixins';

@use 'sass:list';

@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/panel';
@use 'themes/mytheme/variables/overlay';
@use 'themes/mytheme/variables/general';

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  @include mixins.invalid-input();
}

.p-password-panel {
  padding: panel.$panelContentPadding;
  background: panel.$panelContentBg;
  color: panel.$panelContentTextColor;
  border: overlay.$overlayContentBorder;
  box-shadow: form.$inputOverlayShadow;
  border-radius: general.$borderRadius;

  .p-password-meter {
    margin-bottom: general.$inlineSpacing;
    background: form.$passwordMeterBg;

    .p-password-strength {
      &.weak {
        background: form.$passwordWeakBg;
      }

      &.medium {
        background: form.$passwordMediumBg;
      }

      &.strong {
        background: form.$passwordStrongBg;
      }
    }
  }
}

p-password.p-password-clearable {
  .p-password-input {
    padding-right: list.nth(form.$inputPadding, 2) * 2 +
      general.$primeIconFontSize;
  }
  .p-password-clear-icon {
    color: form.$inputIconColor;
    right: list.nth(form.$inputPadding, 2);
  }
}

p-password.p-password-clearable.p-password-mask {
  .p-password-input {
    padding-right: list.nth(form.$inputPadding, 2) * 3 +
      general.$primeIconFontSize * 2;
  }

  .p-password-clear-icon {
    color: form.$inputIconColor;
    right: list.nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
  }
}
