@use 'pattern/utility';
@use 'variables';
@use 'themes/mytheme/variables/data';
@use 'themes/mytheme/variables/general';

.p-paginator {
  background: data.$paginatorBg;
  color: variables.$basic-tones-dnv-medium-dark;
  border: data.$paginatorBorder;
  border-width: data.$paginatorBorderWidth;
  padding: utility.get-rem(15) 0;
  border-radius: general.$borderRadius;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    background-color: data.$paginatorElementBg;
    border: data.$paginatorElementBorder;
    color: variables.$primary-dnv-medium-blue;
    min-width: data.$paginatorElementWidth;
    height: data.$paginatorElementHeight;
    margin: data.$paginatorElementMargin;
    transition: general.$listItemTransition;
    border-radius: data.$paginatorElementBorderRadius;

    &:not(.p-disabled):not(.p-highlight):hover {
      background: data.$paginatorElementHoverBg;
      border-color: data.$paginatorElementHoverBorderColor;
      color: data.$paginatorElementIconHoverColor;
    }
  }

  .p-paginator-first {
    border-top-left-radius: data.$paginatorElementBorderRadius;
    border-bottom-left-radius: data.$paginatorElementBorderRadius;
    margin-left: auto;
  }

  .p-paginator-last {
    border-top-right-radius: data.$paginatorElementBorderRadius;
    border-bottom-right-radius: data.$paginatorElementBorderRadius;
    margin-right: auto;
  }

  .p-dropdown {
    margin-left: general.$inlineSpacing;
    margin-right: general.$inlineSpacing;
    height: data.$paginatorElementHeight;
    padding: data.$paginatorDropwdownTextPadding;
    border-color: variables.$stone-gray;

    .p-dropdown-label {
      padding-right: 0;
    }

    .p-dropdown-trigger-icon {
      color: variables.$primary-dnv-dark-blue;
    }
  }

  .p-paginator-page-input {
    margin-left: general.$inlineSpacing;
    margin-right: general.$inlineSpacing;

    .p-inputtext {
      max-width: data.$paginatorElementWidth;
    }
  }

  .p-paginator-current {
    background-color: data.$paginatorElementBg;
    border: data.$paginatorElementBorder;
    color: variables.$basic-tones-dnv-medium-dark;
    min-width: data.$paginatorElementWidth;
    height: data.$paginatorElementHeight;
    margin: data.$paginatorElementMargin;
  }

  .p-paginator-pages {
    .p-paginator-page {
      background-color: data.$paginatorElementBg;
      border: data.$paginatorElementBorder;
      color: variables.$black;
      min-width: data.$paginatorElementWidth;
      height: data.$paginatorElementHeight;
      margin: data.$paginatorElementMargin;
      transition: general.$listItemTransition;
      border-radius: data.$paginatorElementBorderRadius;

      &.p-highlight {
        background: variables.$primary-dnv-sky-light-blue;
        border-color: general.$highlightBg;
        color: variables.$primary-dnv-medium-blue;
      }

      &:not(.p-highlight):hover {
        background: data.$paginatorElementHoverBg;
        border-color: data.$paginatorElementHoverBorderColor;
        color: data.$paginatorElementIconHoverColor;
      }
    }
  }
}
