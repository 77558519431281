@use '../../mixins';

@use 'themes/mytheme/variables/button';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/panel';

.p-tabmenu {
  .p-tabmenu-nav {
    background: panel.$tabviewNavBg;
    border: panel.$tabviewNavBorder;
    border-width: panel.$tabviewNavBorderWidth;

    .p-menuitem-badge {
      @include mixins.menuitem-badge();
    }

    .p-tabmenuitem {
      margin-right: panel.$tabviewHeaderSpacing;

      .p-menuitem-link {
        border: panel.$tabviewHeaderBorder;
        border-width: panel.$tabviewHeaderBorderWidth;
        border-color: panel.$tabviewHeaderBorderColor;
        background: panel.$tabviewHeaderBg;
        color: panel.$tabviewHeaderTextColor;
        padding: panel.$tabviewHeaderPadding;
        font-weight: panel.$tabviewHeaderFontWeight;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;
        transition: general.$listItemTransition;
        margin: panel.$tabviewHeaderMargin;

        .p-menuitem-icon {
          margin-right: general.$inlineSpacing;
        }

        &:not(.p-disabled):focus-visible {
          @include mixins.focused-inset();
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-menuitem-link {
          background: panel.$tabviewHeaderHoverBg;
          border-color: panel.$tabviewHeaderHoverBorderColor;
          color: panel.$tabviewHeaderTextHoverColor;
        }
      }

      &.p-highlight {
        .p-menuitem-link {
          background: panel.$tabviewHeaderActiveBg;
          border-color: panel.$tabviewHeaderActiveBorderColor;
          color: panel.$tabviewHeaderTextActiveColor;
        }
      }
    }
  }

  .p-tabmenu-left-icon {
    margin-right: general.$inlineSpacing;
  }

  .p-tabmenu-right-icon {
    margin-left: general.$inlineSpacing;
  }

  .p-tabmenu-nav-btn.p-link {
    background: panel.$tabviewHeaderActiveBg;
    color: panel.$tabviewHeaderTextActiveColor;
    width: button.$buttonIconOnlyWidth;
    box-shadow: button.$raisedButtonShadow;
    border-radius: 0;

    &:focus {
      @include mixins.focused-inset();
    }
  }
}
