@use '../../mixins';

@use 'sass:math';
@use 'themes/mytheme/variables/button';
@use 'themes/mytheme/variables/general';

.p-speeddial-button {
  &.p-button.p-button-icon-only {
    width: button.$speedDialButtonWidth;
    height: button.$speedDialButtonHeight;

    .p-button-icon {
      font-size: button.$speedDialButtonIconFontSize;
    }

    .p-icon {
      width: button.$speedDialButtonIconFontSize;
      height: button.$speedDialButtonIconFontSize;
    }
  }
}

.p-speeddial-list {
  outline: 0 none;
}

.p-speeddial-item {
  &.p-focus > .p-speeddial-action {
    @include mixins.focused();
  }
}

.p-speeddial-action {
  width: button.$speedDialActionWidth;
  height: button.$speedDialActionHeight;
  background: button.$speedDialActionBg;
  color: button.$speedDialActionTextColor;

  &:hover {
    background: button.$speedDialActionHoverBg;
    color: button.$speedDialActionTextHoverColor;
  }
}

.p-speeddial-direction-up {
  .p-speeddial-item {
    margin: math.div(general.$inlineSpacing, 2) 0;

    &:first-child {
      margin-bottom: general.$inlineSpacing;
    }
  }
}

.p-speeddial-direction-down {
  .p-speeddial-item {
    margin: math.div(general.$inlineSpacing, 2) 0;

    &:first-child {
      margin-top: general.$inlineSpacing;
    }
  }
}

.p-speeddial-direction-left {
  .p-speeddial-item {
    margin: 0 math.div(general.$inlineSpacing, 2);

    &:first-child {
      margin-right: general.$inlineSpacing;
    }
  }
}

.p-speeddial-direction-right {
  .p-speeddial-item {
    margin: 0 math.div(general.$inlineSpacing, 2);

    &:first-child {
      margin-left: general.$inlineSpacing;
    }
  }
}

.p-speeddial-circle,
.p-speeddial-semi-circle,
.p-speeddial-quarter-circle {
  .p-speeddial-item {
    margin: 0;

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}

.p-speeddial-mask {
  background-color: general.$maskBg;
}
