@use '../../mixins';

@use 'sass:color';
@use 'themes/mytheme/variables/data';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/panel';

.p-organizationchart {
  .p-organizationchart-node-content {
    &.p-organizationchart-selectable-node:not(.p-highlight):hover {
      background: form.$inputListItemHoverBg;
      color: form.$inputListItemTextHoverColor;
    }

    &.p-highlight {
      background: general.$highlightBg;
      color: general.$highlightTextColor;

      .p-node-toggler {
        i {
          color: color.adjust(general.$highlightBg, $lightness: -25%);
        }
      }
    }
  }

  .p-organizationchart-line-down {
    background: data.$organizationChartConnectorColor;
  }

  .p-organizationchart-line-left {
    border-right: panel.$panelContentBorder;
    border-color: data.$organizationChartConnectorColor;
  }

  .p-organizationchart-line-top {
    border-top: panel.$panelContentBorder;
    border-color: data.$organizationChartConnectorColor;
  }

  .p-organizationchart-node-content {
    border: panel.$panelContentBorder;
    background: panel.$panelContentBg;
    color: panel.$panelContentTextColor;
    padding: panel.$panelContentPadding;
  }

  .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;

    &:focus-visible {
      @include mixins.focused();
    }
  }
}
