@use '../../mixins';

@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/menu';

.p-menu {
  padding: menu.$verticalMenuPadding;
  background: menu.$menuBg;
  color: menu.$menuTextColor;
  border: menu.$menuBorder;
  border-radius: general.$borderRadius;
  width: menu.$menuWidth;

  .p-menuitem {
    @include mixins.menuitem();
  }

  &.p-menu-overlay {
    background: menu.$overlayMenuBg;
    border: menu.$overlayMenuBorder;
    box-shadow: menu.$overlayMenuShadow;
  }

  .p-submenu-header {
    margin: menu.$submenuHeaderMargin;
    padding: menu.$submenuHeaderPadding;
    color: menu.$submenuHeaderTextColor;
    background: menu.$submenuHeaderBg;
    font-weight: menu.$submenuHeaderFontWeight;
    border-top-right-radius: menu.$submenuHeaderBorderRadius;
    border-top-left-radius: menu.$submenuHeaderBorderRadius;
  }

  .p-menuitem-separator {
    border-top: general.$divider;
    margin: menu.$menuSeparatorMargin;
  }

  .p-menuitem-badge {
    @include mixins.menuitem-badge();
  }
}
