@use '../../mixins';

@use 'sass:list';
@use 'variables';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';

.p-inputtext {
  font-feature-settings: var(--font-feature-settings, normal);
  background: form.$inputBg;
  padding: form.$inputPadding;
  border: form.$inputBorder;
  transition: general.$formElementTransition;
  appearance: none;
  border-radius: variables.$border-radius-checkbox;

  &:enabled:hover {
    border-color: form.$inputHoverBorderColor;
  }

  &:enabled:focus {
    @include mixins.focused-input();
  }

  &.ng-dirty.ng-invalid {
    @include mixins.invalid-input();
  }

  &.p-inputtext-sm {
    @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleSM);
    @include mixins.scaledPadding(form.$inputPadding, general.$scaleSM);
  }

  &.p-inputtext-lg {
    @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleLG);
    @include mixins.scaledPadding(form.$inputPadding, general.$scaleLG);
  }
}

.p-float-label > label {
  left: list.nth(form.$inputPadding, 2);
  color: form.$inputPlaceholderTextColor;
  transition-duration: general.$transitionDuration;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: form.$inputErrorBorderColor;
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left > i:first-of-type {
  left: list.nth(form.$inputPadding, 2);
  color: form.$inputIconColor;
}

.p-input-icon-left > .p-inputtext {
  padding-left: list.nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
}

.p-input-icon-left.p-float-label > label {
  left: list.nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
}

.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: list.nth(form.$inputPadding, 2);
  color: form.$inputIconColor;
}

.p-input-icon-right > .p-inputtext {
  padding-right: list.nth(form.$inputPadding, 2) * 2 +
    general.$primeIconFontSize;
}

.p-icon-field-left > .p-inputtext {
  padding-left: list.nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
}

.p-icon-field-left.p-float-label > label {
  left: list.nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
}

.p-icon-field-right > .p-inputtext {
  padding-right: list.nth(form.$inputPadding, 2) * 2 +
    general.$primeIconFontSize;
}

@include mixins.placeholder {
  color: form.$inputPlaceholderTextColor;
}

.p-input-filled {
  .p-inputtext {
    background-color: form.$inputFilledBg;

    &:enabled:hover {
      background-color: form.$inputFilledHoverBg;
    }

    &:enabled:focus {
      background-color: form.$inputFilledFocusBg;
    }
  }
}

.p-inputtext-sm {
  .p-inputtext {
    @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleSM);
    @include mixins.scaledPadding(form.$inputPadding, general.$scaleSM);
  }
}

.p-inputtext-lg {
  .p-inputtext {
    @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleLG);
    @include mixins.scaledPadding(form.$inputPadding, general.$scaleLG);
  }
}
