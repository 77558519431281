@use '../../mixins';

@use 'sass:list';
@use 'variables';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/menu';

.p-dropdown {
  background: form.$inputBg;
  border: form.$inputBorder;
  transition: general.$formElementTransition;
  border-radius: general.$borderRadius;

  &:not(.p-disabled):hover {
    border-color: form.$inputHoverBorderColor;
  }

  &:not(.p-disabled).p-focus {
    @include mixins.focused-input();
  }

  &.p-dropdown-clearable {
    .p-dropdown-label {
      padding-right: list.nth(form.$inputPadding, 2) +
        general.$primeIconFontSize;
    }
  }

  .p-dropdown-label {
    background: transparent;
    border: 0 none;

    &.p-placeholder {
      color: form.$inputPlaceholderTextColor;
    }

    &:focus,
    &:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }
  }

  .p-dropdown-trigger {
    background: transparent;
    color: form.$inputIconColor;
    width: form.$inputGroupAddOnMinWidth;
    border-top-right-radius: general.$borderRadius;
    border-bottom-right-radius: general.$borderRadius;
  }

  .p-dropdown-clear-icon {
    color: form.$inputIconColor;
    right: form.$inputGroupAddOnMinWidth;
  }

  &.p-invalid.p-component {
    @include mixins.invalid-input();
  }
}

.p-dropdown-panel {
  background: form.$inputOverlayBg;
  color: form.$inputListTextColor;
  border: form.$inputOverlayBorder;
  border-radius: general.$borderRadius;
  box-shadow: form.$inputOverlayShadow;

  .p-dropdown-header {
    padding: form.$inputListHeaderPadding;
    border-bottom: form.$inputListHeaderBorder;
    color: form.$inputListHeaderTextColor;
    background: form.$inputOverlayHeaderBg;
    margin: form.$inputListHeaderMargin;
    border-top-right-radius: general.$borderRadius;
    border-top-left-radius: general.$borderRadius;

    .p-dropdown-filter {
      padding-right: list.nth(form.$inputPadding, 2) +
        general.$primeIconFontSize;
      margin-right: -1 *
        (list.nth(form.$inputPadding, 2) + general.$primeIconFontSize);
    }

    .p-dropdown-filter-icon {
      right: list.nth(form.$inputPadding, 2);
      color: form.$inputIconColor;
    }
  }

  .p-dropdown-items {
    padding: form.$inputListPadding;

    .p-dropdown-item {
      margin: form.$inputListItemMargin;
      padding: form.$inputListItemPadding;
      border: form.$inputListItemBorder;
      color: form.$inputListItemTextColor;
      background: form.$inputListItemBg;
      transition: general.$listItemTransition;
      border-radius: form.$inputListItemBorderRadius;

      &.p-highlight {
        background: variables.$primary-dnv-sky-light-blue;
        color: variables.$primary-dnv-medium-blue;

        &.p-focus {
          background: variables.$primary-dnv-sky-light-blue;
          color: variables.$primary-dnv-medium-blue;
        }
      }

      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
          color: form.$inputListItemTextFocusColor;
          background: form.$inputListItemFocusBg;
        }

        &:hover {
          color: form.$inputListItemTextHoverColor;
          background: form.$inputListItemHoverBg;
        }
      }
    }

    .p-dropdown-item-group {
      margin: menu.$submenuHeaderMargin;
      padding: menu.$submenuHeaderPadding;
      color: menu.$submenuHeaderTextColor;
      background: menu.$submenuHeaderBg;
      font-weight: menu.$submenuHeaderFontWeight;
    }

    .p-dropdown-empty-message {
      padding: form.$inputListItemPadding;
      color: form.$inputListItemTextColor;
      background: form.$inputListItemBg;
    }
  }
}

.p-input-filled {
  .p-dropdown {
    background: form.$inputFilledBg;

    &:not(.p-disabled):hover {
      background-color: form.$inputFilledHoverBg;
    }

    &:not(.p-disabled).p-focus {
      background-color: form.$inputFilledFocusBg;

      .p-inputtext {
        background-color: transparent;
      }
    }
  }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  @include mixins.invalid-input();
}
