@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/misc';

.p-progressbar {
  border: misc.$progressBarBorder;
  height: misc.$progressBarHeight;
  background: misc.$progressBarBg;
  border-radius: general.$borderRadius;

  .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: misc.$progressBarValueBg;
  }

  .p-progressbar-label {
    color: misc.$progressBarValueTextColor;
    line-height: misc.$progressBarHeight;
  }
}
