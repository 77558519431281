@use 'mixins';

@use 'themes/mytheme/variables/general';

* {
  box-sizing: border-box;
}

.p-component {
  font-weight: general.$fontWeight;
}

.p-component-overlay {
  background-color: general.$maskBg;
  transition-duration: general.$transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: general.$disabledOpacity;
}

.p-error {
  color: general.$errorColor;
}

.p-text-secondary {
  color: general.$textSecondaryColor;
}

.pi {
  font-size: general.$primeIconFontSize;
}

.p-icon {
  width: general.$primeIconFontSize;
  height: general.$primeIconFontSize;
}

.p-link {
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: general.$fontSize;
  border-radius: general.$borderRadius;

  &:focus-visible {
    @include mixins.focused();
  }
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }

  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
}

p-checkbox.p-element,
p-tristatecheckbox.p-element {
  display: flex;
  align-items: center;
  gap: 8px;
}
