@use '../../mixins';

@use 'sass:math';
@use 'sass:list';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/misc';

.p-chips {
  &:not(.p-disabled):hover {
    .p-chips-multiple-container {
      border-color: form.$inputHoverBorderColor;
    }
  }

  &:not(.p-disabled).p-focus {
    .p-chips-multiple-container {
      @include mixins.focused-input();
    }
  }

  .p-chips-multiple-container {
    padding: math.div(list.nth(form.$inputPadding, 1), 2)
      list.nth(form.$inputPadding, 2);
    gap: general.$inlineSpacing;

    .p-chips-token {
      padding: math.div(list.nth(form.$inputPadding, 1), 2)
        list.nth(form.$inputPadding, 2);
      margin-right: general.$inlineSpacing;
      background: misc.$chipBg;
      color: misc.$chipTextColor;
      border-radius: misc.$chipBorderRadius;

      &.p-focus {
        background: misc.$chipFocusBg;
        color: misc.$chipFocusTextColor;
      }

      .p-chips-token-icon {
        margin-left: general.$inlineSpacing;
      }
    }

    .p-chips-input-token {
      padding: math.div(list.nth(form.$inputPadding, 1), 2) 0;

      input {
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: form.$inputTextFontSize;
        color: general.$textColor;
        padding: 0;
        margin: 0;
      }
    }
  }
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  @include mixins.invalid-input();
}

p-chips.p-chips-clearable {
  .p-inputtext {
    padding-right: list.nth(form.$inputPadding, 2) + general.$primeIconFontSize;
  }

  .p-chips-clear-icon {
    color: form.$inputIconColor;
    right: list.nth(form.$inputPadding, 2);
  }
}
