@use '../../mixins';

@use 'sass:math';
@use 'sass:list';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/button';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/misc';
@use 'themes/mytheme/variables/menu';

.p-autocomplete {
  .p-autocomplete-loader {
    right: list.nth(form.$inputPadding, 2);
  }

  &.p-autocomplete-dd {
    .p-autocomplete-loader {
      right: list.nth(form.$inputPadding, 2) + button.$buttonIconOnlyWidth;
    }
  }

  &:not(.p-disabled):hover {
    .p-autocomplete-multiple-container {
      border-color: form.$inputHoverBorderColor;
    }
  }

  &:not(.p-disabled).p-focus {
    .p-autocomplete-multiple-container {
      @include mixins.focused-input();
    }
  }

  .p-autocomplete-multiple-container {
    padding: math.div(list.nth(form.$inputPadding, 1), 2)
      list.nth(form.$inputPadding, 2);
    gap: general.$inlineSpacing;

    .p-autocomplete-input-token {
      padding: math.div(list.nth(form.$inputPadding, 1), 2) 0;

      input {
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: form.$inputTextFontSize;
        color: general.$textColor;
        padding: 0;
        margin: 0;
      }
    }

    .p-autocomplete-token {
      padding: math.div(list.nth(form.$inputPadding, 1), 2)
        list.nth(form.$inputPadding, 2);
      background: misc.$chipBg;
      color: misc.$chipTextColor;
      border-radius: misc.$chipBorderRadius;

      .p-autocomplete-token-icon {
        margin-left: general.$inlineSpacing;
      }

      &.p-focus {
        background: misc.$chipFocusBg;
        color: misc.$chipFocusTextColor;
      }
    }
  }

  &.p-invalid.p-component > .p-inputtext {
    @include mixins.invalid-input();
  }
}

.p-autocomplete-panel {
  background: form.$inputOverlayBg;
  color: form.$inputListTextColor;
  border: form.$inputOverlayBorder;
  border-radius: general.$borderRadius;
  box-shadow: form.$inputOverlayShadow;

  .p-autocomplete-items {
    padding: form.$inputListPadding;

    .p-autocomplete-item {
      margin: form.$inputListItemMargin;
      padding: form.$inputListItemPadding;
      border: form.$inputListItemBorder;
      color: form.$inputListItemTextColor;
      background: form.$inputListItemBg;
      transition: general.$listItemTransition;
      border-radius: form.$inputListItemBorderRadius;

      &.p-highlight {
        color: general.$highlightTextColor;
        background: general.$highlightBg;

        &.p-focus {
          background: general.$highlightFocusBg;
        }
      }

      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
          color: form.$inputListItemTextFocusColor;
          background: form.$inputListItemFocusBg;
        }

        &:hover {
          color: form.$inputListItemTextHoverColor;
          background: form.$inputListItemHoverBg;
        }
      }
    }

    .p-autocomplete-item-group {
      margin: menu.$submenuHeaderMargin;
      padding: menu.$submenuHeaderPadding;
      color: menu.$submenuHeaderTextColor;
      background: menu.$submenuHeaderBg;
      font-weight: menu.$submenuHeaderFontWeight;
    }

    .p-autocomplete-empty-message {
      padding: form.$inputListItemPadding;
      color: form.$inputListItemTextColor;
      background: form.$inputListItemBg;
    }
  }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  @include mixins.invalid-input();
}

p-autocomplete.p-autocomplete-clearable {
  .p-inputtext {
    padding-right: list.nth(form.$inputPadding, 2) * 2 +
      general.$primeIconFontSize;
  }

  .p-autocomplete-clear-icon {
    color: form.$inputIconColor;
    right: list.nth(form.$inputPadding, 2);
  }
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd {
  .p-autocomplete-clear-icon {
    color: form.$inputIconColor;
    right: button.$buttonIconOnlyWidth + list.nth(form.$inputPadding, 2);
  }
}
