@use '../../mixins';

@use 'variables';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/menu';
@use 'themes/mytheme/variables/panel';

.p-column-filter-row {
  .p-column-filter-menu-button,
  .p-column-filter-clear-button {
    margin-left: general.$inlineSpacing;
  }
}

.p-column-filter-menu-button {
  width: general.$actionIconWidth;
  height: general.$actionIconHeight;
  color: general.$actionIconColor;
  border: general.$actionIconBorder;
  background: general.$actionIconBg;
  border-radius: general.$actionIconBorderRadius;
  transition: general.$actionIconTransition;

  &:hover {
    color: general.$actionIconHoverColor;
    border-color: general.$actionIconHoverBorderColor;
    background: general.$actionIconHoverBg;
  }

  &.p-column-filter-menu-button-open,
  &.p-column-filter-menu-button-open:hover {
    background: unset;
    color: variables.$primary-dnv-medium-blue;
  }

  &.p-column-filter-menu-button-active,
  &.p-column-filter-menu-button-active:hover {
    background: unset;
    color: variables.$primary-dnv-medium-blue;
  }

  &:focus-visible {
    @include mixins.focused();
  }
}

.p-column-filter-clear-button {
  width: general.$actionIconWidth;
  height: general.$actionIconHeight;
  color: general.$actionIconColor;
  border: general.$actionIconBorder;
  background: general.$actionIconBg;
  border-radius: general.$actionIconBorderRadius;
  transition: general.$actionIconTransition;

  &:hover {
    color: general.$actionIconHoverColor;
    border-color: general.$actionIconHoverBorderColor;
    background: general.$actionIconHoverBg;
  }

  &:focus-visible {
    @include mixins.focused();
  }
}

.p-column-filter-overlay {
  background: form.$inputOverlayBg;
  color: form.$inputListTextColor;
  border: form.$inputOverlayBorder;
  border-radius: general.$borderRadius;
  box-shadow: form.$inputOverlayShadow;
  min-width: menu.$menuWidth;

  .p-column-filter-row-items {
    padding: form.$inputListPadding;

    .p-column-filter-row-item {
      margin: form.$inputListItemMargin;
      padding: form.$inputListItemPadding;
      border: form.$inputListItemBorder;
      color: form.$inputListItemTextColor;
      background: form.$inputListItemBg;
      transition: general.$listItemTransition;
      border-radius: form.$inputListItemBorderRadius;

      &.p-highlight {
        color: general.$highlightTextColor;
        background: general.$highlightBg;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: form.$inputListItemTextHoverColor;
        background: form.$inputListItemHoverBg;
      }

      &:focus-visible {
        @include mixins.focused-listitem();
      }
    }

    .p-column-filter-separator {
      border-top: general.$divider;
      margin: menu.$menuSeparatorMargin;
    }
  }
}

.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    padding: form.$inputListHeaderPadding;
    border-bottom: form.$inputListHeaderBorder;
    color: form.$inputListHeaderTextColor;
    background: form.$inputOverlayHeaderBg;
    margin: form.$inputListHeaderMargin;
    border-top-right-radius: general.$borderRadius;
    border-top-left-radius: general.$borderRadius;
  }

  .p-column-filter-constraint {
    padding: 0;
    border-bottom: general.$divider;
    .p-column-filter-matchmode-dropdown {
      margin-bottom: 0;
    }

    .p-column-filter-remove-button {
      margin-top: general.$inlineSpacing;
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .p-column-filter-add-rule {
    padding: panel.$panelFooterPadding;
  }

  .p-column-filter-buttonbar {
    display: none;
  }
}
