@use 'sass:list';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/form';

.p-inputotp {
  display: flex;
  align-items: center;
  gap: general.$inlineSpacing;
}

.p-inputotp-input {
  text-align: center;
  width: 2 * list.nth(form.$inputPadding, 2) + 1rem;
}
