@use '../../mixins';

@use 'sass:math';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/form';

.p-rating {
  gap: general.$inlineSpacing;

  .p-rating-item {
    .p-rating-icon {
      color: form.$ratingStarIconOffColor;
      transition: general.$formElementTransition;
      font-size: form.$ratingIconFontSize;

      &.p-icon {
        width: form.$ratingIconFontSize;
        height: form.$ratingIconFontSize;
      }

      &.p-rating-cancel {
        color: form.$ratingCancelIconColor;
      }
    }

    &.p-focus {
      @include mixins.focused-input();
    }

    &.p-rating-item-active {
      .p-rating-icon {
        color: form.$ratingStarIconOnColor;
      }
    }
  }

  &:not(.p-disabled):not(.p-readonly) {
    .p-rating-item {
      &:hover {
        .p-rating-icon {
          color: form.$ratingStarIconHoverColor;

          &.p-rating-cancel {
            color: form.$ratingCancelIconHoverColor;
          }
        }
      }
    }
  }
}

@if (general.$highlightBg == form.$ratingStarIconOnColor) {
  .p-highlight {
    .p-rating {
      .p-rating-item {
        &.p-rating-item-active {
          .p-rating-icon {
            color: general.$highlightTextColor;
          }
        }
      }
    }
  }
}
