@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/misc';

.p-scrolltop {
  width: misc.$scrollTopWidth;
  height: misc.$scrollTopHeight;
  border-radius: misc.$scrollTopBorderRadius;
  box-shadow: form.$inputOverlayShadow;
  transition: general.$actionIconTransition;

  &.p-link {
    background: misc.$scrollTopBg;

    &:hover {
      background: misc.$scrollTopHoverBg;
    }
  }

  .p-scrolltop-icon {
    font-size: misc.$scrollTopFontSize;
    color: misc.$scrollTopTextColor;
  }

  .p-icon {
    width: misc.$scrollTopFontSize;
    height: misc.$scrollTopFontSize;
  }
}
