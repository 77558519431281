@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/button';

.p-inputgroup-addon {
  background: form.$inputGroupBg;
  color: form.$inputGroupTextColor;
  border-top: form.$inputBorder;
  border-left: form.$inputBorder;
  border-bottom: form.$inputBorder;
  padding: form.$inputPadding;
  min-width: form.$inputGroupAddOnMinWidth;

  &:last-child {
    border-right: form.$inputBorder;
  }
}

.p-inputgroup {
  > .p-component,
  > .p-inputwrapper > .p-inputtext,
  > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;

    + .p-inputgroup-addon {
      border-left: 0 none;
    }

    &:focus {
      z-index: 1;

      ~ label {
        z-index: 1;
      }
    }
  }
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: general.$borderRadius;
  border-bottom-left-radius: general.$borderRadius;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: general.$borderRadius;
  border-bottom-left-radius: general.$borderRadius;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: general.$borderRadius;
  border-bottom-right-radius: general.$borderRadius;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: general.$borderRadius;
  border-bottom-right-radius: general.$borderRadius;
}

.p-fluid {
  .p-inputgroup {
    .p-button {
      width: auto;

      &.p-button-icon-only {
        width: button.$buttonIconOnlyWidth;
      }
    }
  }
}
