@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/overlay';

.p-tooltip {
  .p-tooltip-text {
    background: overlay.$tooltipBg;
    color: overlay.$tooltipTextColor;
    padding: overlay.$tooltipPadding;
    box-shadow: form.$inputOverlayShadow;
    border-radius: general.$borderRadius;
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: overlay.$tooltipBg;
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: overlay.$tooltipBg;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: overlay.$tooltipBg;
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: overlay.$tooltipBg;
    }
  }
}
