@use '../../mixins';

@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/menu';

.p-slidemenu {
  padding: menu.$verticalMenuPadding;
  background: menu.$menuBg;
  color: menu.$menuTextColor;
  border: menu.$menuBorder;
  border-radius: general.$borderRadius;
  width: menu.$menuWidth;

  .p-slidemenu-root-list {
    outline: 0 none;
  }

  .p-submenu-list {
    outline: 0 none;
  }

  .p-menuitem {
    @include mixins.menuitem();
  }

  &.p-slidemenu-overlay {
    background: menu.$overlayMenuBg;
    border: menu.$overlayMenuBorder;
    box-shadow: menu.$overlayMenuShadow;
  }

  .p-slidemenu-list {
    padding: menu.$verticalMenuPadding;
    background: menu.$overlayMenuBg;
    border: menu.$overlayMenuBorder;
    box-shadow: menu.$overlayMenuShadow;
  }

  .p-menuitem-separator {
    border-top: general.$divider;
    margin: menu.$menuSeparatorMargin;
  }

  .p-slidemenu-icon {
    font-size: menu.$menuitemSubmenuIconFontSize;
  }

  .p-icon {
    width: menu.$menuitemSubmenuIconFontSize;
    height: menu.$menuitemSubmenuIconFontSize;
  }

  .p-slidemenu-backward {
    padding: menu.$menuitemPadding;
    color: menu.$menuitemTextColor;
    &:not(.p-disabled):focus {
      @include mixins.focused-inset();
    }
  }

  .p-menuitem-badge {
    @include mixins.menuitem-badge();
  }
}
