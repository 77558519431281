@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/misc';
@use 'themes/mytheme/variables/panel';

.p-avatar {
  background-color: misc.$avatarBg;
  border-radius: general.$borderRadius;

  &.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;

    .p-avatar-icon {
      font-size: 1.5rem;
    }
  }

  &.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;

    .p-avatar-icon {
      font-size: 2rem;
    }
  }
}

.p-avatar-group {
  .p-avatar {
    border: 2px solid panel.$panelContentBg;
  }
}
