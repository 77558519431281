@use '../../mixins';

@use 'themes/mytheme/variables/data';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/menu';
@use 'themes/mytheme/variables/panel';

.p-panelmenu {
  .p-panelmenu-header {
    outline: 0 none;

    .p-panelmenu-header-content {
      border: panel.$accordionHeaderBorder;
      color: panel.$accordionHeaderTextColor;
      background: panel.$accordionHeaderBg;
      border-radius: general.$borderRadius;
      transition: general.$listItemTransition;

      .p-panelmenu-header-action {
        color: panel.$accordionHeaderTextColor;
        padding: panel.$accordionHeaderPadding;
        font-weight: panel.$accordionHeaderFontWeight;

        .p-submenu-icon {
          margin-right: general.$inlineSpacing;
        }

        .p-menuitem-icon {
          margin-right: general.$inlineSpacing;
        }
      }
    }

    &:not(.p-disabled) {
      &:focus-visible {
        .p-panelmenu-header-content {
          @include mixins.focused-inset();
        }
      }
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      .p-panelmenu-header-content {
        background: panel.$accordionHeaderHoverBg;
        border-color: panel.$accordionHeaderHoverBorderColor;
        color: panel.$accordionHeaderTextHoverColor;
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-panelmenu-header-content {
        background: panel.$accordionHeaderActiveBg;
        border-color: panel.$accordionHeaderActiveBorderColor;
        color: panel.$accordionHeaderTextActiveColor;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
      }

      &:hover {
        .p-panelmenu-header-content {
          border-color: panel.$accordionHeaderActiveHoverBorderColor;
          background: panel.$accordionHeaderActiveHoverBg;
          color: panel.$accordionHeaderTextActiveHoverColor;
        }
      }
    }
  }

  .p-panelmenu-content {
    padding: menu.$verticalMenuPadding;
    border: panel.$accordionContentBorder;
    background: panel.$accordionContentBg;
    color: panel.$accordionContentTextColor;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: general.$borderRadius;
    border-bottom-left-radius: general.$borderRadius;

    .p-panelmenu-root-list {
      outline: 0 none;
    }

    .p-menuitem {
      @include mixins.menuitem();

      .p-menuitem-content {
        .p-menuitem-link {
          .p-submenu-icon {
            margin-right: general.$inlineSpacing;
          }
        }
      }
    }

    .p-menuitem-separator {
      border-top: general.$divider;
      margin: menu.$menuSeparatorMargin;
    }

    .p-submenu-list:not(.p-panelmenu-root-list) {
      padding: data.$treeNodeChildrenPadding;
    }
  }

  .p-panelmenu-panel {
    margin-bottom: panel.$accordionSpacing;

    @if panel.$accordionSpacing == 0 {
      .p-panelmenu-header {
        .p-panelmenu-header-content {
          border-radius: 0;
        }
      }

      .p-panelmenu-content {
        border-radius: 0;
      }

      &:not(:first-child) {
        .p-panelmenu-header {
          .p-panelmenu-header-content {
            border-top: 0 none;
          }

          &:not(.p-highlight):not(.p-disabled):hover,
          &:not(.p-disabled).p-highlight:hover {
            .p-panelmenu-header-content {
              border-top: 0 none;
            }
          }
        }
      }

      &:first-child {
        .p-panelmenu-header {
          .p-panelmenu-header-content {
            border-top-right-radius: general.$borderRadius;
            border-top-left-radius: general.$borderRadius;
          }
        }
      }

      &:last-child {
        .p-panelmenu-header:not(.p-highlight) {
          .p-panelmenu-header-content {
            border-bottom-right-radius: general.$borderRadius;
            border-bottom-left-radius: general.$borderRadius;
          }
        }

        .p-panelmenu-content {
          border-bottom-right-radius: general.$borderRadius;
          border-bottom-left-radius: general.$borderRadius;
        }
      }
    }
  }
}
