@use '../../mixins';

@use 'variables';
@use 'themes/mytheme/variables/button';
@use 'themes/mytheme/variables/general';

.p-button {
  color: button.$buttonTextColor;
  background: button.$buttonBg;
  border: button.$buttonBorder;
  padding: button.$buttonPadding;
  font-size: general.$fontSize;
  transition: general.$formElementTransition;
  border-radius: general.$borderRadius;

  &:not(:disabled):hover {
    background: button.$buttonHoverBg;
    color: button.$buttonTextHoverColor;
    border-color: button.$buttonHoverBorderColor;
  }

  &:not(:disabled):active {
    background: button.$buttonActiveBg;
    color: button.$buttonTextActiveColor;
    border-color: button.$buttonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: button.$buttonBg;
    border: button.$outlinedButtonBorder;

    &:not(:disabled):hover {
      background: rgba(button.$buttonBg, button.$textButtonHoverBgOpacity);
      color: button.$buttonBg;
      border: button.$outlinedButtonBorder;
    }

    &:not(:disabled):active {
      background: rgba(button.$buttonBg, button.$textButtonActiveBgOpacity);
      color: button.$buttonBg;
      border: button.$outlinedButtonBorder;
    }

    &.p-button-plain {
      color: button.$plainButtonTextColor;
      border-color: button.$plainButtonTextColor;

      &:not(:disabled):hover {
        background: button.$plainButtonHoverBgColor;
        color: button.$plainButtonTextColor;
      }

      &:not(:disabled):active {
        background: button.$plainButtonActiveBgColor;
        color: button.$plainButtonTextColor;
      }
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: button.$buttonBg;
    border-color: transparent;

    &:not(:disabled):hover {
      background: rgba(button.$buttonBg, button.$textButtonHoverBgOpacity);
      color: button.$buttonBg;
      border-color: transparent;
    }

    &:not(:disabled):active {
      background: rgba(button.$buttonBg, button.$textButtonActiveBgOpacity);
      color: button.$buttonBg;
      border-color: transparent;
    }

    &.p-button-plain {
      color: button.$plainButtonTextColor;

      &:not(:disabled):hover {
        background: button.$plainButtonHoverBgColor;
        color: button.$plainButtonTextColor;
      }

      &:not(:disabled):active {
        background: button.$plainButtonActiveBgColor;
        color: button.$plainButtonTextColor;
      }
    }
  }

  &:focus {
    @include mixins.focused();
  }

  .p-button-label {
    transition-duration: general.$transitionDuration;
  }

  .p-button-icon-left {
    margin-right: general.$inlineSpacing;
  }

  .p-button-icon-right {
    margin-left: general.$inlineSpacing;
  }

  .p-button-icon-bottom {
    margin-top: general.$inlineSpacing;
  }

  .p-button-icon-top {
    margin-bottom: general.$inlineSpacing;
  }

  .p-badge {
    margin-left: general.$inlineSpacing;
    min-width: general.$fontSize;
    height: general.$fontSize;
    line-height: general.$fontSize;
    color: button.$buttonBg;
    background-color: button.$buttonTextColor;
  }

  &.p-button-raised {
    box-shadow: button.$raisedButtonShadow;
  }

  &.p-button-rounded {
    border-radius: button.$roundedButtonBorderRadius;
  }

  &.p-button-icon-only {
    width: button.$buttonIconOnlyWidth;
    padding: button.$buttonIconOnlyPadding;

    .p-button-icon-left,
    .p-button-icon-right {
      margin: 0;
    }

    &.p-button-rounded {
      border-radius: 50%;
      height: button.$buttonIconOnlyWidth;
    }
  }

  &.p-button-sm {
    @include mixins.scaledFontSize(general.$fontSize, general.$scaleSM);
    @include mixins.scaledPadding(button.$buttonPadding, general.$scaleSM);

    .p-button-icon {
      @include mixins.scaledFontSize(
        general.$primeIconFontSize,
        general.$scaleSM
      );
    }
  }

  &.p-button-lg {
    @include mixins.scaledFontSize(general.$fontSize, general.$scaleLG);
    @include mixins.scaledPadding(button.$buttonPadding, general.$scaleLG);

    .p-button-icon {
      @include mixins.scaledFontSize(
        general.$primeIconFontSize,
        general.$scaleLG
      );
    }
  }

  &.p-button-loading-label-only {
    .p-button-label {
      margin-left: general.$inlineSpacing;
    }

    .p-button-loading-icon {
      margin-right: 0;
    }
  }
}

.p-fluid {
  .p-button {
    width: 100%;
  }

  .p-button-icon-only {
    width: button.$buttonIconOnlyWidth;
  }

  .p-buttonset {
    display: flex;

    .p-button {
      flex: 1;
    }
  }
}

.p-button.p-button-primary {
  background-color: variables.$primary-dnv-medium-blue;
  color: variables.$button-primary-text;
  border: none;

  &:disabled {
    color: variables.$button-disabled-text;
    background-color: variables.$button-disabled-background;
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: button.$secondaryButtonTextColor;
  background: button.$secondaryButtonBg;
  border: button.$secondaryButtonBorder;

  &:not(:disabled):hover {
    background: button.$secondaryButtonHoverBg;
    color: button.$secondaryButtonTextHoverColor;
    border-color: button.$secondaryButtonHoverBorderColor;
  }

  &:not(:disabled):focus {
    box-shadow: button.$secondaryButtonFocusShadow;
  }

  &:not(:disabled):active {
    background: button.$secondaryButtonActiveBg;
    color: button.$secondaryButtonTextActiveColor;
    border-color: button.$secondaryButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: button.$secondaryButtonBg;
    border: button.$outlinedButtonBorder;

    &:not(:disabled):hover {
      background: rgba(
        button.$secondaryButtonBg,
        button.$textButtonHoverBgOpacity
      );
      color: button.$secondaryButtonBg;
      border: button.$outlinedButtonBorder;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$secondaryButtonBg,
        button.$textButtonActiveBgOpacity
      );
      color: button.$secondaryButtonBg;
      border: button.$outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: button.$secondaryButtonBg;
    border-color: transparent;

    &:not(:disabled):hover {
      background: rgba(
        button.$secondaryButtonBg,
        button.$textButtonHoverBgOpacity
      );
      border-color: transparent;
      color: button.$secondaryButtonBg;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$secondaryButtonBg,
        button.$textButtonActiveBgOpacity
      );
      border-color: transparent;
      color: button.$secondaryButtonBg;
    }
  }
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: button.$infoButtonTextColor;
  background: button.$infoButtonBg;
  border: button.$infoButtonBorder;

  &:not(:disabled):hover {
    background: button.$infoButtonHoverBg;
    color: button.$infoButtonTextHoverColor;
    border-color: button.$infoButtonHoverBorderColor;
  }

  &:not(:disabled):focus {
    box-shadow: button.$infoButtonFocusShadow;
  }

  &:not(:disabled):active {
    background: button.$infoButtonActiveBg;
    color: button.$infoButtonTextActiveColor;
    border-color: button.$infoButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: button.$infoButtonBg;
    border: button.$outlinedButtonBorder;

    &:not(:disabled):hover {
      background: rgba(button.$infoButtonBg, button.$textButtonHoverBgOpacity);
      color: button.$infoButtonBg;
      border: button.$outlinedButtonBorder;
    }

    &:not(:disabled):active {
      background: rgba(button.$infoButtonBg, button.$textButtonActiveBgOpacity);
      color: button.$infoButtonBg;
      border: button.$outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: button.$infoButtonBg;
    border-color: transparent;

    &:not(:disabled):hover {
      background: rgba(button.$infoButtonBg, button.$textButtonHoverBgOpacity);
      border-color: transparent;
      color: button.$infoButtonBg;
    }

    &:not(:disabled):active {
      background: rgba(button.$infoButtonBg, button.$textButtonActiveBgOpacity);
      border-color: transparent;
      color: button.$infoButtonBg;
    }
  }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: button.$successButtonTextColor;
  background: button.$successButtonBg;
  border: button.$successButtonBorder;

  &:not(:disabled):hover {
    background: button.$successButtonHoverBg;
    color: button.$successButtonTextHoverColor;
    border-color: button.$successButtonHoverBorderColor;
  }

  &:not(:disabled):focus {
    box-shadow: button.$successButtonFocusShadow;
  }

  &:not(:disabled):active {
    background: button.$successButtonActiveBg;
    color: button.$successButtonTextActiveColor;
    border-color: button.$successButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: button.$successButtonBg;
    border: button.$outlinedButtonBorder;

    &:not(:disabled):hover {
      background: rgba(
        button.$successButtonBg,
        button.$textButtonHoverBgOpacity
      );
      color: button.$successButtonBg;
      border: button.$outlinedButtonBorder;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$successButtonBg,
        button.$textButtonActiveBgOpacity
      );
      color: button.$successButtonBg;
      border: button.$outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: button.$successButtonBg;
    border-color: transparent;

    &:not(:disabled):hover {
      background: rgba(
        button.$successButtonBg,
        button.$textButtonHoverBgOpacity
      );
      border-color: transparent;
      color: button.$successButtonBg;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$successButtonBg,
        button.$textButtonActiveBgOpacity
      );
      border-color: transparent;
      color: button.$successButtonBg;
    }
  }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: button.$warningButtonTextColor;
  background: button.$warningButtonBg;
  border: button.$warningButtonBorder;

  &:not(:disabled):hover {
    background: button.$warningButtonHoverBg;
    color: button.$warningButtonTextHoverColor;
    border-color: button.$warningButtonHoverBorderColor;
  }

  &:not(:disabled):focus {
    box-shadow: button.$warningButtonFocusShadow;
  }

  &:not(:disabled):active {
    background: button.$warningButtonActiveBg;
    color: button.$warningButtonTextActiveColor;
    border-color: button.$warningButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: button.$warningButtonBg;
    border: button.$outlinedButtonBorder;

    &:not(:disabled):hover {
      background: rgba(
        button.$warningButtonBg,
        button.$textButtonHoverBgOpacity
      );
      color: button.$warningButtonBg;
      border: button.$outlinedButtonBorder;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$warningButtonBg,
        button.$textButtonActiveBgOpacity
      );
      color: button.$warningButtonBg;
      border: button.$outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: button.$warningButtonBg;
    border-color: transparent;

    &:not(:disabled):hover {
      background: rgba(
        button.$warningButtonBg,
        button.$textButtonHoverBgOpacity
      );
      border-color: transparent;
      color: button.$warningButtonBg;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$warningButtonBg,
        button.$textButtonActiveBgOpacity
      );
      border-color: transparent;
      color: button.$warningButtonBg;
    }
  }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: button.$helpButtonTextColor;
  background: button.$helpButtonBg;
  border: button.$helpButtonBorder;

  &:not(:disabled):hover {
    background: button.$helpButtonHoverBg;
    color: button.$helpButtonTextHoverColor;
    border-color: button.$helpButtonHoverBorderColor;
  }

  &:not(:disabled):focus {
    box-shadow: button.$helpButtonFocusShadow;
  }

  &:not(:disabled):active {
    background: button.$helpButtonActiveBg;
    color: button.$helpButtonTextActiveColor;
    border-color: button.$helpButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: button.$helpButtonBg;
    border: button.$outlinedButtonBorder;

    &:not(:disabled):hover {
      background: rgba(button.$helpButtonBg, button.$textButtonHoverBgOpacity);
      color: button.$helpButtonBg;
      border: button.$outlinedButtonBorder;
    }

    &:not(:disabled):active {
      background: rgba(button.$helpButtonBg, button.$textButtonActiveBgOpacity);
      color: button.$helpButtonBg;
      border: button.$outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: button.$helpButtonBg;
    border-color: transparent;

    &:not(:disabled):hover {
      background: rgba(button.$helpButtonBg, button.$textButtonHoverBgOpacity);
      border-color: transparent;
      color: button.$helpButtonBg;
    }

    &:not(:disabled):active {
      background: rgba(button.$helpButtonBg, button.$textButtonActiveBgOpacity);
      border-color: transparent;
      color: button.$helpButtonBg;
    }
  }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: button.$dangerButtonTextColor;
  background: button.$dangerButtonBg;
  border: button.$dangerButtonBorder;

  &:not(:disabled):hover {
    background: button.$dangerButtonHoverBg;
    color: button.$dangerButtonTextHoverColor;
    border-color: button.$dangerButtonHoverBorderColor;
  }

  &:not(:disabled):focus {
    box-shadow: button.$dangerButtonFocusShadow;
  }

  &:not(:disabled):active {
    background: button.$dangerButtonActiveBg;
    color: button.$dangerButtonTextActiveColor;
    border-color: button.$dangerButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: button.$dangerButtonBg;
    border: button.$outlinedButtonBorder;

    &:not(:disabled):hover {
      background: rgba(
        button.$dangerButtonBg,
        button.$textButtonHoverBgOpacity
      );
      color: button.$dangerButtonBg;
      border: button.$outlinedButtonBorder;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$dangerButtonBg,
        button.$textButtonActiveBgOpacity
      );
      color: button.$dangerButtonBg;
      border: button.$outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: button.$dangerButtonBg;
    border-color: transparent;

    &:not(:disabled):hover {
      background: rgba(
        button.$dangerButtonBg,
        button.$textButtonHoverBgOpacity
      );
      border-color: transparent;
      color: button.$dangerButtonBg;
    }

    &:not(:disabled):active {
      background: rgba(
        button.$dangerButtonBg,
        button.$textButtonActiveBgOpacity
      );
      border-color: transparent;
      color: button.$dangerButtonBg;
    }
  }
}

.p-button.p-button-link {
  color: button.$linkButtonColor;
  background: transparent;
  border: transparent;

  &:not(:disabled):hover {
    background: transparent;
    color: button.$linkButtonHoverColor;
    border-color: transparent;

    .p-button-label {
      text-decoration: button.$linkButtonTextHoverDecoration;
    }
  }

  &:not(:disabled):focus {
    background: transparent;
    box-shadow: button.$linkButtonFocusShadow;
    border-color: transparent;
  }

  &:not(:disabled):active {
    background: transparent;
    color: button.$linkButtonColor;
    border-color: transparent;
  }
}
