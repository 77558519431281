@use '../../mixins';

@use 'sass:math';
@use 'sass:list';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  @include mixins.invalid-input();
}

p-inputmask.p-inputmask-clearable {
  .p-inputtext {
    padding-right: list.nth(form.$inputPadding, 2) * 2 +
      general.$primeIconFontSize;
  }

  .p-inputmask-clear-icon {
    color: form.$inputIconColor;
    right: list.nth(form.$inputPadding, 2);
  }
}
