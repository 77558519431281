@use 'themes/mytheme/variables/data';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/panel';

.p-dataview {
  .p-paginator-top {
    border-width: data.$tableTopPaginatorBorderWidth;
    border-radius: 0;
  }

  .p-paginator-bottom {
    border-width: data.$tableBottomPaginatorBorderWidth;
    border-radius: 0;
  }

  .p-dataview-header {
    background: data.$tableHeaderBg;
    color: data.$tableHeaderTextColor;
    border: data.$tableHeaderBorder;
    border-width: data.$tableHeaderBorderWidth;
    padding: data.$tableHeaderPadding;
    font-weight: data.$tableHeaderFontWeight;
  }

  .p-dataview-content {
    background: data.$tableBodyRowBg;
    color: data.$tableBodyRowTextColor;
    border: data.$dataViewContentBorder;
    padding: data.$dataViewContentPadding;
  }

  .p-dataview-footer {
    background: data.$tableFooterBg;
    color: data.$tableFooterTextColor;
    border: data.$tableFooterBorder;
    border-width: data.$tableFooterBorderWidth;
    padding: data.$tableFooterPadding;
    font-weight: data.$tableFooterFontWeight;
    border-bottom-left-radius: general.$borderRadius;
    border-bottom-right-radius: general.$borderRadius;
  }

  .p-dataview-loading-icon {
    font-size: data.$tableHeaderCellBg;
  }

  .p-dataview-emptymessage {
    padding: panel.$panelContentPadding;
  }
}
