@use '../../mixins';

@use 'sass:list';
@use 'themes/mytheme/variables/form';
@use 'themes/mytheme/variables/general';
@use 'themes/mytheme/variables/button';

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  @include mixins.invalid-input();
}

p-inputnumber.p-inputnumber-clearable {
  .p-inputnumber-input {
    padding-right: list.nth(form.$inputPadding, 2) * 2 +
      general.$primeIconFontSize;
  }

  .p-inputnumber-clear-icon {
    color: form.$inputIconColor;
    right: list.nth(form.$inputPadding, 2);
  }
}

p-inputnumber.p-inputnumber-clearable {
  .p-inputnumber-buttons-stacked {
    .p-inputnumber-clear-icon {
      right: button.$buttonIconOnlyWidth + list.nth(form.$inputPadding, 2);
    }
  }

  .p-inputnumber-buttons-horizontal {
    .p-inputnumber-clear-icon {
      right: button.$buttonIconOnlyWidth + list.nth(form.$inputPadding, 2);
    }
  }
}
